<template>
  <div class="w-full text-sm">
    <div class="flex flex-row mb-4">
      <div class="flex-col w-full lg:w-2/5">
        <asterix-async-select
          class="select-max-width"
          name="id-platforms-deal-select"
          track-by="id"
          label="name"
          required="required"
          is-array-object
          disable-selected-options
          close-on-select
          :service="getPlatformsDealUnSync"
          @change="pikeupPlatformsDeal"
        />
      </div>
    </div>
    <div class="flex flex-col items-center justify-center w-full pb-8">
      <sun-data-table class="w-full" :headers="headers" :content="platformDeals" striped hoverable :loading="isLoading">
        <template #[`col.name`]="{ item }">
          <sun-data-table-cell class="text-left truncate">
            <div :title="item.name" class="truncate">{{ item.name }}</div>
          </sun-data-table-cell>
        </template>
        <template #[`col.platform.name`]="{ item }">
          <sun-data-table-cell>
            {{ item.platformName }}
          </sun-data-table-cell>
        </template>
        <template #[`col.revenueNet`]="{ item, columnClass }">
          <sun-data-table-cell :class="columnClass">
            {{ item.revenueNet }}
          </sun-data-table-cell>
        </template>
        <template #[`col.actions`]="{ item }">
          <sun-data-table-cell>
            <table-action-menu
              :actions="actions"
              :item="item"
              :items="deal.platformsDeals"
              @click="onActionClick($event, item)"
            />
          </sun-data-table-cell>
        </template>
        <template #empty>
          <asterix-no-data class="bg-white text-center" />
        </template>
      </sun-data-table>
    </div>
    <sun-pagination-page
      class="mb-8"
      :total-pages="totalPages"
      :total-items="totalItems"
      :current-page="currentPage"
      :value="itemsPerPage"
      @changePage="changePage"
      @changePerPage="changeItemsPerPage"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { CREATE_TOAST } from '@/store/modules/toast/keys';
import { Toast } from '@/model/shared/Toast';
import CONFIG from './config';
import Deal from '@/entities/dashboardSSP/Deal';
import AsterixAsyncSelect from '@/components/molecules/shared/AsterixAsyncSelect';
import { edit as editPlatformsDeal } from '@/router/private/modules/dashboardSSP/supply/platformsDeals/edit';
import { updatePlatformDealSync } from '@/services/modules/dashboardSSP/platformDeals/updatePlatformDealSync';
import { getPlatformDealByNameOrId } from '@/services/modules/dashboardSSP/platformDeals/getPlatformDealByNameOrId';
import { getPlatformDeals } from '@/services/modules/dashboardSSP/platformDeals';
import { activeClientMixin } from '@/mixins/common/activeClientMixin';
import { indexMixin } from '@/mixins/index/indexMixin';
import { ACTIVE_CLIENT } from '@/store/modules/context/keys';

export default {
  name: 'PlatformsDeal',
  components: {
    TableActionMenu: () => import('@/components/organisms/shared/TableActionMenu'),
    AsterixNoData: () => import('@/components/organisms/shared/AsterixNoData'),
    AsterixAsyncSelect,
  },
  mixins: [activeClientMixin, indexMixin],
  props: {
    deal: { type: Deal, default: null },
  },
  data: () => ({
    isLoading: false,
    headers: CONFIG.headers,
    actions: [{ name: 'Edit' }],
    platformDeals: [],
  }),
  computed: {
    ...mapGetters({
      activeClient: ACTIVE_CLIENT,
    }),
  },
  created() {
    this.getTableItems();
  },
  methods: {
    ...mapActions({ createToast: CREATE_TOAST }),
    onActionClick(event, item) {
      switch (event.name) {
        case 'Edit':
          this.editDeal(item.id);
          break;
      }
    },
    editDeal(platformDealId) {
      const route = { name: editPlatformsDeal.name, params: { platformDealId } };
      this.$router.push(route);
    },
    getPlatformsDealUnSync(params) {
      const query = params.removeFilter('name');

      return getPlatformDealByNameOrId(query, params);
    },
    async pikeupPlatformsDeal({ items }) {
      try {
        const platformDealId = items.id;
        this.isLoading = true;
        await updatePlatformDealSync(platformDealId, this.deal.id);
        this.createToast(Toast.success('Data updated', 'Data was updated successfully'));
        this.$emit('update', this.deal);
      } catch (e) {
        this.createToast(Toast.error('Deal could not be updated', e.message));
      } finally {
        this.isLoading = false;
      }
    },
    async getTableItems() {
      this.isLoading = true;
      const params = this.setParamsToTable();
      params.addFilter('dealId', this.deal.id);
      params.addFilter('client.id', this.activeClient.id);
      params.page = this.currentPage;
      params.itemsPerPage = this.itemsPerPage;
      const { data, isCancel } = await this.getItemsFromAPI(getPlatformDeals, params);
      this.platformDetals = data;
      if (!isCancel) {
        this.isLoading = false;
      }
    },
    changePage(page) {
      this.goToPage({ page });
    },
  },
};
</script>

<style scoped>
.select-max-width {
  max-width: 600px;
}
</style>
