import Column from '@/model/shared/Column';

export default {
  headers: [
    new Column(`PLATFORM'S DEAL`, 'name', true, false),
    new Column(`PLATFORM'S DEAL ID`, 'externalDealId', true, false).setClass('break-normal'),
    new Column('PLATFORM NAME', 'platform.name', true, false),
    new Column('REVENUE NET LAST 30 DAYS', 'revenueNet', true, false).toRight(),
    new Column('ACTIONS', 'actions', true, false),
  ],
};
