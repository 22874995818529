var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "w-full text-sm" },
    [
      _c("div", { staticClass: "flex flex-row mb-4" }, [
        _c(
          "div",
          { staticClass: "flex-col w-full lg:w-2/5" },
          [
            _c("asterix-async-select", {
              staticClass: "select-max-width",
              attrs: {
                name: "id-platforms-deal-select",
                "track-by": "id",
                label: "name",
                required: "required",
                "is-array-object": "",
                "disable-selected-options": "",
                "close-on-select": "",
                service: _vm.getPlatformsDealUnSync,
              },
              on: { change: _vm.pikeupPlatformsDeal },
            }),
          ],
          1
        ),
      ]),
      _c(
        "div",
        {
          staticClass: "flex flex-col items-center justify-center w-full pb-8",
        },
        [
          _c("sun-data-table", {
            staticClass: "w-full",
            attrs: {
              headers: _vm.headers,
              content: _vm.platformDeals,
              striped: "",
              hoverable: "",
              loading: _vm.isLoading,
            },
            scopedSlots: _vm._u(
              [
                {
                  key: `col.name`,
                  fn: function ({ item }) {
                    return [
                      _c(
                        "sun-data-table-cell",
                        { staticClass: "text-left truncate" },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "truncate",
                              attrs: { title: item.name },
                            },
                            [_vm._v(_vm._s(item.name))]
                          ),
                        ]
                      ),
                    ]
                  },
                },
                {
                  key: `col.platform.name`,
                  fn: function ({ item }) {
                    return [
                      _c("sun-data-table-cell", [
                        _vm._v(" " + _vm._s(item.platformName) + " "),
                      ]),
                    ]
                  },
                },
                {
                  key: `col.revenueNet`,
                  fn: function ({ item, columnClass }) {
                    return [
                      _c("sun-data-table-cell", { class: columnClass }, [
                        _vm._v(" " + _vm._s(item.revenueNet) + " "),
                      ]),
                    ]
                  },
                },
                {
                  key: `col.actions`,
                  fn: function ({ item }) {
                    return [
                      _c(
                        "sun-data-table-cell",
                        [
                          _c("table-action-menu", {
                            attrs: {
                              actions: _vm.actions,
                              item: item,
                              items: _vm.deal.platformsDeals,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.onActionClick($event, item)
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  },
                },
                {
                  key: "empty",
                  fn: function () {
                    return [
                      _c("asterix-no-data", {
                        staticClass: "bg-white text-center",
                      }),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              true
            ),
          }),
        ],
        1
      ),
      _c("sun-pagination-page", {
        staticClass: "mb-8",
        attrs: {
          "total-pages": _vm.totalPages,
          "total-items": _vm.totalItems,
          "current-page": _vm.currentPage,
          value: _vm.itemsPerPage,
        },
        on: {
          changePage: _vm.changePage,
          changePerPage: _vm.changeItemsPerPage,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }